const metadata = {
  id: 1,
  title: 'AI displaying macronutrients of a meal',
  date: '2024-11-01',
  excerpt: 'A quick way for people to track their food intake by protein, carbs, fats',
  //featuredImage: igCollectionImage,
};

const ProjectsPost = () => {
  return (
    <article className="prose prose-gray mx-auto dark:prose-invert">
      <h1>{metadata.title}</h1>
      <div className="content">
        <p>
          I really enjoy classic physiques and deeply appreciate that exercise and lifting weights is only a small factor of achieving this look. Diet and nutrition is the most important thing to consider in order to get a body like this:
        </p>
        <p>
          Diets are not very fun though so I loosely use a <a href="https://www.medicalnewstoday.com/articles/323863" target="_blank" rel="noopener noreferrer">IIFYM</a> diet which I've simplified with only two goals. Firstly, hit 150g protein per day. Secondly, limit carbs and fat - limit meaning only one meal a day with a heavy amount of carbs. It's not very precise but I find it works for me because I know how I feel after eating things and can roughly estimate from looking at a meal what the macronutrients are. It also helps that I cook most of my meals so the routine makes things fairly predictable. Three meals a day of around 50g protein each. Mornings is no carbs, lunch is normal meals, and dinner is a big meal.
        </p>
        <p>
          I know at the start of the fitness jourey it can be overwhelming for anyone new to nutrition to quickly see what the relevant macros are for each item. You can of course check labels and measure the weights and g/100g of each macro but I don't think it needs to be that accurate e unless you have aims of being a bodybuilder.
        </p>
        <p>
          So, wouldn't it be sweet if we had more accurate ways of estimating macros e.g. using your phone to take an image and scan your meal in realtime? I see some Apps have made this possible but the accuracy is not quite there yet. Also, I'm not sure if this is a pain problem for those who want to become healthier or fitter so there is only a little opportunity here but I would like to expand my computer vision skills doing this project.
        </p>
        <p>
          Currently I have been testing HuggingFace's Spaces with Gradio and have a prototpe using Google GEMINI model. It's not perfect on the exact macros but surprisingly accurate on the list of ingredients within each meal. With more prompting I can reverse engineer how the model calculated the figures - current hunch is that it assumes average size of ingredients and looks at size ratios of ingredients of the whole image. This means it may not take into account anomalies e.g. if a meat was an extra lean cut vs fatty cut, these small differences could be important for those hyper conscious of tracking their macros.
          Given my lack of budget and no validated market demand for such high precision yet, it doesn't make sense for me to train my own model from scratch so I will use pretrained models for now.
        </p>
        <p>
          <a href="https://huggingface.co/spaces/minetexter/food-macronutrients" target="_blank" rel="noopener noreferrer">Here's the link to the Space to upload your image</a>. You can even clone it and play around with the model configs yourself! Please ignore the output formatting - it's not perfect but I'm not interested in this trivial part. I want to focus on improvin accuracy of output first perhaps using prompt engineering to understand how the model calculates the figures.
        </p>
      </div>
    </article>
  );
};

// Export both the metadata and the component
export default {
  ...metadata,
  component: ProjectsPost
};
